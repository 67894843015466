import CardModel from "./CardModel";

export default interface CardPairModel {
    card1: CardModel,
    card2: CardModel,
}

const isCardInPair = function (card: CardModel, pair: CardPairModel): boolean {
    return card.id === pair.card1.id || card.id === pair.card2.id;
};

export const isValidPair = function (cards: CardModel[], pairs: CardPairModel[]): boolean {
    if (cards.length !== 2) {
        return false;
    }
    return pairs.filter((pair: CardPairModel) => {
        return isCardInPair(cards[0], pair) && isCardInPair(cards[1], pair);
    }).length > 0;
};
