import React from "react";
import classNames from "classnames";
import CardModel from "../models/CardModel";
import back_face from "../assets/images/back-face.png";

interface CardProps {
    model: CardModel,
    onClick: (model: CardModel) => void,
}

function Card({model, onClick}: CardProps) {
    return (
        <div className="w-1/4 px-2 py-2 flex justify-around" onClick={() => onClick(model)}>
            <div className="relative memory-card">
                <img src={model.faceFront} className="front-face" alt="" style={{borderRadius: '6px'}}/>
                <img src={back_face}
                     className={classNames("back-face transition-opacity duration-500", {'opacity-0': model.isOpen})}
                     style={{borderRadius: '6px'}}
                     alt="" />
            </div>
        </div>
    );
};

export default Card;
