import React, {useEffect, useState} from "react";
import {Difficulty} from "../App";
import bridge from '@vkontakte/vk-bridge';
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ScreenGameWinParams {
    moves: number,
    time: number,
    difficulty: Difficulty,
    onRestart: () => void,
}

function ScreenGameWin ({moves, time, onRestart, difficulty}: ScreenGameWinParams) {

    const [medal, setMedal] = useState<string|undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [isStoryPublished, setStoryPublished] = useState<boolean>(false);
    const [isWallPublished, setWallPublished] = useState<boolean>(false);

    async function postWall() {
        let attach = 'photo-89879811_457239055';
        if (difficulty === Difficulty.EASY) {
            attach = 'photo-89879811_457239054';
        }
        if (difficulty === Difficulty.MEDIUM) {
            attach = 'photo-89879811_457239056';
        }
        if (difficulty === Difficulty.HARD) {
            attach = 'photo-89879811_457239055';
        }

        if (process.env.NODE_ENV !== 'production') {
            return;
        }
        const url_params = new URLSearchParams(window.location.search);
        const user_id = url_params.get('vk_user_id');
        if (!user_id) {
            return;
        }

        let getTokenResponse;
        try {
            getTokenResponse = await bridge.send("VKWebAppGetAuthToken", {"app_id": 7452508, "scope": "wall"});
        }
        catch (e) {

            return;
        }
        if (!getTokenResponse.access_token) {
            return;
        }
        const access_token = getTokenResponse.access_token;

        let r;
        try {
            r = await bridge.send("VKWebAppShowWallPostBox", {"message": "@cerebro_vk (Церебро Таргет) 6 лет! Ура!", "attachments": attach});
        }
        catch (e) {
            console.log(e);
            return;
        }
        setWallPublished(true);
        toast.success('Пост опубликован');
        axios.post('/api/save-post.php', {user_id: user_id, difficulty: difficulty});
    }

    async function postStories() {
        if (process.env.NODE_ENV !== 'production') {
            return;
        }
        const url_params = new URLSearchParams(window.location.search);
        const user_id = url_params.get('vk_user_id');
        if (!user_id) {
            return;
        }

        let getTokenResponse;
        try {
            getTokenResponse = await bridge.send("VKWebAppGetAuthToken", {"app_id": 7452508, "scope": "stories"});
        }
        catch (e) {

            return;
        }
        if (!getTokenResponse.access_token) {
            return;
        }
        const access_token = getTokenResponse.access_token;

        const params = {
            method: "stories.getPhotoUploadServer",
            params: {
                add_to_news: 1,
                //user_ids: user_id.toString(),
                //link_url: 'https://vk.com/cerebro_vk',
                link_url: 'https://vk.com/app7452508',
                access_token: access_token,
                v: "5.103",
            },
            request_id: "stories",
        };
        let getUploadServerResponse;
        try {
            getUploadServerResponse = await bridge.send('VKWebAppCallAPIMethod', params);
        }
        catch (e) {
            console.log(e);
        }
        if (!getUploadServerResponse) {
            return;
        }
        console.log(getUploadServerResponse);
        const upload_url = getUploadServerResponse.response.upload_url;
        const url = "/api/post-story.php";
        axios.post(url, {user_id: user_id, upload_url: upload_url, difficulty: difficulty}).then(r => {
            if (r.data.success) {
                toast.success('История опубликована');
                setStoryPublished(true);
                return;
            }
            toast.error('Почему-то не удалось опубликовать историю :(');
            return;
        }).catch(err => {
            toast.error('Почему-то не удалось опубликовать историю :(');
            return;
        });
    }

    useEffect(() => {
        if (difficulty === Difficulty.EASY) {
            setMedal('./medals/bronze-medal.png');
            setTitle('Ура победителю!');
            setText('Попробуем сыграть на более сложном уровне?')
        }
        if (difficulty === Difficulty.MEDIUM) {
            setMedal('/medals/silver-medal.png');
            setTitle('Ура, получилось!');
            setText('Сыграем на сложном уровне?')
        }
        if (difficulty === Difficulty.HARD) {
            setMedal('./medals/gold-medal.png');
            setTitle('Огонь!');
            setText('Игра пройдена на максимальной сложности!')
        }
    }, [difficulty]);

    return (
        <div className="over-screen-container">
            <ToastContainer position="bottom-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
            <div className="flex flex-wrap">
                <div className="flex w-full pt-5 justify-center">
                    <div className="w-2/4">
                        <img src={medal} alt="" />
                    </div>
                </div>
                <div className="w-full">
                    <h1 className="text-center text-lg md:text-4xl text-white pt-10">{title}</h1>
                    <h1 className="text-center text-lg md:text-4xl text-white">{text}</h1>
                </div>
                <div className="flex w-full justify-center py-10">
                    <button
                        className="w-3/4 pushy__btn pushy__btn--cerebro pushy__btn--lg"
                        onClick={() => onRestart()}
                    >Сыграть ещё</button>
                </div>
                <div className="flex w-full justify-center pb-10">
                    {!isStoryPublished &&
                        <button
                            className="w-3/4 pushy__btn pushy__btn--cerebro pushy__btn--lg"
                            onClick={() => postStories()}
                        >Поделиться в сториз</button>
                    }
                    {isStoryPublished && <h1 className="text-center text-lg md:text-4xl text-white">История опубликована</h1>}
                </div>
                <div className="flex w-full justify-center pb-10">
                    {!isWallPublished &&
                    <button
                        className="w-3/4 pushy__btn pushy__btn--cerebro pushy__btn--lg"
                        onClick={() => postWall()}
                    >Опубликовать на стене</button>
                    }
                    {isWallPublished && <h1 className="text-center text-lg md:text-4xl text-white">Пост опубликован</h1>}
                </div>
                <div className="flex flex-wrap justify-center w-full pb-10">
                    <div className="info-container w-full md:w-auto text-center text-sm md:text-xl mx-5 px-10 py-1">Ходов: {moves}</div>
                    <div className="info-container w-full md:w-auto text-center mt-2 md:mt-0 text-sm md:text-xl mx-5 px-10 py-1">Секунд: {time}</div>
                </div>
            </div>
        </div>
    );
}

export default ScreenGameWin;
