import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import bridge from '@vkontakte/vk-bridge';
import axios from "axios";

bridge.send('VKWebAppInit');
if (process.env.NODE_ENV === 'production') {
    const url_params = new URLSearchParams(window.location.search);
    if (url_params.has('vk_user_id')) {
        axios.post('/api/save-visit.php', {user_id: url_params.get('vk_user_id')});
    }
}


ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
