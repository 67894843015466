import React from 'react';
import "../buttons.css";

import sad from "../assets/images/sad.png";

interface ScreenGameOverParams {
    onRestart: () => void,
}

function ScreenGameOver({onRestart}: ScreenGameOverParams) {
    return (
        <div className="over-screen-container">
            <div className="flex flex-wrap">
                <div className="flex w-full pt-5 justify-center">
                    <div className="w-2/4">
                        <img src={sad} alt="" />
                    </div>
                </div>
                <div className="w-full">
                    <h1 className="text-center text-lg md:text-4xl text-white pt-10">Время вышло.</h1>
                    <h1 className="text-center text-lg md:text-4xl text-white">Не беда! Попробуйте ещё разок!</h1>
                </div>
                <div className="flex w-full justify-center py-10">
                    <button
                        className="w-3/4 pushy__btn pushy__btn--cerebro pushy__btn--lg"
                        onClick={() => onRestart()}
                    >Попробовать ещё</button>
                </div>
            </div>
        </div>
    );
}

export default ScreenGameOver;
