import React, {useState} from 'react';

import "./tailwind-generated.css";

import ScreenGame from "./screens/ScreenGame";
import ScreenGameStart from "./screens/ScreenGameStart";
import ScreenGameOver from "./screens/ScreenGameOver";
import ScreenGameWin from "./screens/ScreenGameWin";
import ScreenRules from "./screens/ScreenRules";

export enum Difficulty{
    EASY,
    MEDIUM,
    HARD
}

function App() {

    enum Screens {
        "GameStart" = "GAME_START",
        "Rules" = "RULES",
        "Game" = "GAME",
        "GameOver" = "GAME_OVER",
        "GameWin" = "GAME_WIN"
    }

    const [currentScreen, setCurrentScreen] = useState<Screens>(Screens.GameStart);
    const [difficulty, setDifficulty] = useState<Difficulty>(Difficulty.MEDIUM);
    const [winMoves, setWinMoves] = useState<number>(0);
    const [winTime, setWinTime] = useState<number>(0);

    const onWin = (moves: number, time: number) => {
        setWinMoves(moves);
        setWinTime(time);
        setCurrentScreen(Screens.GameWin);
    };

    const onLoose = () => {
        setCurrentScreen(Screens.GameOver);
    };

    const onRestart = () => {
        setCurrentScreen(Screens.GameStart);
    };

    const onDifficultyChange = (difficulty: Difficulty) => {
        setDifficulty(difficulty);
    };



    return (
        <div style={{'maxWidth': '800px', height: '100%'}}>
            {currentScreen === Screens.GameStart &&
                <ScreenGameStart
                    onStart={() => setCurrentScreen(Screens.Game)}
                    onRules={() => setCurrentScreen(Screens.Rules)}
                    selectedDifficulty={difficulty}
                    onDifficultyChange={onDifficultyChange}/>
            }
            {currentScreen === Screens.Rules &&
                <ScreenRules onBack={() => setCurrentScreen(Screens.GameStart)}/>
            }
            {currentScreen === Screens.Game &&
                <ScreenGame onWin={onWin} onLoose={onLoose} difficulty={difficulty}/>
            }
            {currentScreen === Screens.GameOver &&
                <ScreenGameOver onRestart={onRestart}/>
            }
            {currentScreen === Screens.GameWin &&
                <ScreenGameWin moves={winMoves} time={winTime} difficulty={difficulty} onRestart={onRestart}/>
            }
        </div>
    );
}

export default App;
