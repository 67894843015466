import React, {useEffect, useState} from "react";
import useInterval from "../hooks/useInterval";

import CardModel from "../models/CardModel";
import CardPairModel, {isValidPair} from "../models/CardPairModel";
import AllCardsPairs, {getAllCardsFromPairs} from "../data/AllCardsPairs";

import Card from "../components/Card";

import _shuffle from "lodash/shuffle";
import _findIndex from "lodash/findIndex";
import {Difficulty} from "../App";



interface ScreenGameProps {
    difficulty: Difficulty,
    onWin: (moves:number, time: number) => void,
    onLoose: () => void,
}

function ScreenGame({onWin, onLoose, difficulty}: ScreenGameProps) {
    const HIDE_DELAY: number = 1000;

    const [cardPairs] = useState<CardPairModel[]>(AllCardsPairs);
    const [tableCards, setTableCards] = useState<CardModel[]>([]);
    const [openCards, setOpenCards] = useState<CardModel[]>([]);
    const [moves, setMoves] = useState<number>(0);
    const [timeTotal, setTimeTotal] = useState<number>(0);
    const [timeSpent, setTimeSpent] = useState<number>(0);
    const [hideTimeout, setHideTimeout] = useState<number|null>(null);

    // on mount
    useEffect(() => {
        if (difficulty === Difficulty.EASY) {
            setTimeTotal(180);
        }
        if (difficulty === Difficulty.MEDIUM) {
            setTimeTotal(120);
        }
        if (difficulty === Difficulty.HARD) {
            setTimeTotal(60);
        }
        const cards: CardModel[] = _shuffle(getAllCardsFromPairs(cardPairs))
            .map(card => {
                card.isOpen = false;
                return card;
            });
        setTableCards(cards);
    }, [cardPairs, difficulty]);

    useInterval(() => {
        setTimeSpent(timeSpent + 1);
    }, 1000);

    // check loose (time expired)
    useEffect(() => {
        if (timeTotal > 0 && timeTotal - timeSpent <= 0) {
            onLoose();
        }
    }, [timeTotal, timeSpent, onLoose]);
    // check win (opened cards === total cards on board)
    useEffect(() => {
        if (tableCards.length === 0) {
            return;
        }
        const openCardsCount = tableCards.filter(card => {
            return card.isOpen;
        }).length;
        if (openCardsCount === tableCards.length) {
            onWin(moves, timeSpent);
        }
    }, [onWin, tableCards, moves, timeSpent]);

    // unmount cleanup
    useEffect(() => {
        return () => {
            if (hideTimeout !== null) {
                clearTimeout(hideTimeout);
            }
        }
    }, [hideTimeout]);

    const cardClickHandler = function (card: CardModel) {
        if (card.isOpen || openCards.length >= 2) {
            return;
        }
        setMoves(moves + 1);
        card.isOpen = true;
        changeCardState(card);
        addOpenedCard(card);
        checkMatch([...openCards, card]);
    };

    function changeCardState(card: CardModel): void {
        const cards = [...tableCards];
        const index = _findIndex(cards, (item) => item.id === card.id);
        cards.splice(index, 1, card);
        setTableCards(cards);
    }

    function addOpenedCard(card: CardModel): void {
        const cards = [...openCards];
        if (cards.length < 2) {
            cards.push(card);
        }
        setOpenCards(cards);
    }

    function checkMatch(openCards: CardModel[]): void {
        if (openCards.length !== 2) {
            return;
        }

        if (!isValidPair(openCards, cardPairs)) {
            hideOpenCards(openCards);
            return;
        }

        setOpenCards([]);
    }

    function hideOpenCards(openCards: CardModel[]) {
        const timeoutId: number = window.setTimeout(() => {
            openCards.forEach((card: CardModel) => {
                card.isOpen = false;
                changeCardState(card);
                setOpenCards([]);
            });
        }, HIDE_DELAY);
        setHideTimeout(timeoutId);
    }


    return (
        <div className="game-screen-container">
            <div className="flex flex-wrap py-5 justify-center">
                <div className="info-container w-full md:w-auto text-center text-sm md:text-xl mx-5 px-10 py-1">Ходов: {moves}</div>
                <div className="info-container w-full md:w-auto text-center mt-2 md:mt-0 text-sm md:text-xl mx-5 px-10 py-1">Осталось времени: {timeTotal - timeSpent}</div>
            </div>
            <div className="flex flex-wrap">
                {tableCards.map(cardModel =>
                    <Card model={cardModel} onClick={cardClickHandler} key={cardModel.id}/>)
                }
            </div>
        </div>
    );
}

export default ScreenGame;
