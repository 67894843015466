import React, {useEffect, useState} from "react";
import Select from "react-select";

import _find from "lodash/findIndex";

import img_logo from "../assets/images/logo.png";
import img_six_years from "../assets/images/six-years.png";
import img_cake from "../assets/images/cake.png";

import "../buttons.css";
import {Difficulty} from "../App";

interface ScreenGameStartParams {
    selectedDifficulty: Difficulty,
    onStart: () => void,
    onRules: () => void,
    onDifficultyChange: (difficulty: Difficulty) => void,
}

function ScreenGameStart({selectedDifficulty, onStart, onRules, onDifficultyChange}: ScreenGameStartParams) {

    const [options] = useState([
        { value: Difficulty.EASY, label: 'Легкий' },
        { value: Difficulty.MEDIUM, label: 'Средний' },
        { value: Difficulty.HARD, label: 'Сложный' }
    ]);

    const [selectedValue, setSelectedValue] = useState(options[0]);

    useEffect(() => {
        const index = _find(options, option => option.value === selectedDifficulty);
        setSelectedValue(options[index]);
    }, [selectedDifficulty, options]);

    const onChange = function (data: any): void {
        const typed: {value: Difficulty} = data as {value: Difficulty};
        onDifficultyChange(typed.value);
        //console.log(data);
    };

    return (
        <div className="start-screen-container flex flex-wrap">
            <div className="w-full mt-10">
                <div className="w-full flex justify-center">
                    <img src={img_logo} alt="Лого Церебро"/>
                </div>
                <div className="w-full flex justify-center mt-10">
                    <img src={img_six_years} alt="Нам шесть лет"/>
                </div>
                <div>
                    <p className="text-center text-white text-xl md:text-3xl mt-10">Праздничная игра с Церебро.<br/>Проверьте свою память и логику!<br/>Найдите все пары картинок!</p>
                </div>
                <div className="flex w-full justify-center mt-10">
                    <div className="flex w-3/4 flex-col items-center">
                        <p className="text-white text-lg self-start">Уровень сложности:</p>
                        <Select className="w-full" placeholder="Уровень сложности" onChange={onChange} options={options} value={selectedValue}/>
                    </div>
                </div>
                <div className="flex justify-center mt-10 mb-10">
                    <button className="pushy__btn pushy__btn--cerebro pushy__btn--lg" onClick={() => onStart()}>Начать</button>
                    <button className="pushy__btn pushy__btn--cerebro pushy__btn--lg ml-2" onClick={() => onRules()}>Правила</button>
                </div>

            </div>
            <div className="flex w-full justify-center mb-10">
                <img src={img_cake} alt="Торт"/>
            </div>
        </div>
    );
}

export default ScreenGameStart;
