import CardPairModel from "../models/CardPairModel";
import CardModel from "../models/CardModel";

const AllCardsPairs: CardPairModel[] = [
    {
        card1: {
            id: 1,
            isOpen: false,
            faceFront: './cards/01_alarm.png',
            title: 'p1_c1',
        },
        card2: {
            id: 2,
            isOpen: false,
            faceFront: './cards/01_sleep.png',
            title: 'p1_c2',
        }
    },
    {
        card1: {
            id: 3,
            isOpen: false,
            faceFront: './cards/02_notepad.png',
            title: 'p2_c1',
        },
        card2: {
            id: 4,
            isOpen: false,
            faceFront: './cards/02_pencil.png',
            title: 'p2_c2',
        }
    },
    {
        card1: {
            id: 5,
            isOpen: false,
            faceFront: './cards/03_flower.png',
            title: 'p3_c1',
        },
        card2: {
            id: 6,
            isOpen: false,
            faceFront: './cards/03_vase.png',
            title: 'p3_c2',
        }
    },
    {
        card1: {
            id: 7,
            isOpen: false,
            faceFront: './cards/04_dog.png',
            title: 'p4_c1',
        },
        card2: {
            id: 8,
            isOpen: false,
            faceFront: './cards/04_bone.png',
            title: 'p4_c2',
        }
    },
    {
        card1: {
            id: 9,
            isOpen: false,
            faceFront: './cards/05_heart.png',
            title: 'p5_c1',
        },
        card2: {
            id: 10,
            isOpen: false,
            faceFront: './cards/05_like.png',
            title: 'p5_c2',
        }
    },
    {
        card1: {
            id: 11,
            isOpen: false,
            faceFront: './cards/06_felix.png',
            title: 'p6_c1',
        },
        card2: {
            id: 12,
            isOpen: false,
            faceFront: './cards/06_idea.png',
            title: 'p6_c2',
        }
    },
    {
        card1: {
            id: 13,
            isOpen: false,
            faceFront: './cards/07_notebook.png',
            title: 'p7_c1',
        },
        card2: {
            id: 14,
            isOpen: false,
            faceFront: './cards/07_charge.png',
            title: 'p7_c2',
        }
    },
    {
        card1: {
            id: 15,
            isOpen: false,
            faceFront: './cards/08_cerebro.png',
            title: 'p8_c1',
        },
        card2: {
            id: 16,
            isOpen: false,
            faceFront: './cards/08_cake.png',
            title: 'p8_c2',
        }
    },
];

export const getAllCardsFromPairs = function (pairs: CardPairModel[]): CardModel[] {
    return pairs.map(pair => {
        return [pair.card1, pair.card2];
    }).flat();
};

export default AllCardsPairs;
