import React from "react";
import "../buttons.css";

interface ScreenRulesProps {
    onBack: () => void
}

function ScreenRules ({onBack}: ScreenRulesProps) {
    return (
        <div className="rules-screen-container flex flex-wrap">
            <div className="w-full mt-10 px-10">
                <div className="w-full bg-white rounded p-10">
                    <p>Ваша задача - открыть все карточки на игровом поле.</p>
                    <p>За один ход можно открыть две карточки - просто кликайте на каждую по очереди. Если картинки на них оказались парными - они останутся открытыми до конца игры, если нет - перевернутся рубашкой вверх.</p>
                    <p>Запоминайте, что было на них изображено, открывайте остальные карточки и ищите к каждой картинке пару.</p>
                    <p>В отличие от классических игр Memory в этой игре в парах карточек изображения не одинаковы. Нужно найти логическую пару каждой картинке. Например, для лого ЦереброТаргет это тортик (у нас же день рождения), а для ручки - блокнот и т.д.</p>
                </div>
                <div className="flex justify-center mt-10 mb-10">
                    <button className="pushy__btn pushy__btn--cerebro pushy__btn--lg" onClick={() => onBack()}>Назад</button>
                </div>
            </div>
        </div>
    );
}

export default ScreenRules;
